.user-profile-sidebar {
  display: none;
  background-color: $card-bg;
  border-left: 4px solid $border-color;
  min-width: 380px;
  max-width: 380px;
  height: 80vh !important;

  @media (min-width: 992px) {
    border-left: 4px solid $border-color;
  }

  @media (max-width: 1199.98px) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }

  @media (max-width: 575.98px) {
    min-width: 100%;
  }
}

.user-image {
  position: relative;
  top: -35px;
  margin-bottom: -35px;
}

.timeline {
  display: flex;
  min-width: 380px;
  padding: 0;
  margin: 0;
  max-width: 380px;
  overflow: auto;
  height: -moz-calc(80% - (220px));
  height: -webkit-calc(80% - (220px));
  height: calc(100% - (220px));
}

.user-profile-desc {
  height: calc(100% - 370px);

  @media (max-width: 991.98px) {
    height: calc(100% - 370px);
  }
}

.iconRating {
  background-color: $card-bg !important;
}
