//
// avatar.scss
//
.avatar-x {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 4px;
}

.avatar-xs {
  height: 3rem;
  width: 3rem;
  margin-right: 15px;
}
.avatar-48 {
  height: 48px;
  width: 48px;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-xxl {
  height: 8.5rem;
  width: 8.5rem;
}

.avatar-title {
  align-items: center;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-title .avatar-x {
  font-size: small;
}
