@import 'components/mixins.scss';

@font-face {
  font-family: 'Avenir Next Rounded Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Regular'),
    url('../css/fonts/AvenirNextRoundedProRegular.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Italic'),
    url('../css/fonts/AvenirNextRoundedProRegularIt.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Medium'),
    url('../css/fonts/AvenirNextRoundedProMedium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Medium Italic'),
    url('../css/fonts/AvenirNextRoundedProMediumIt.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Demi';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Demi'),
    url('../css/fonts/AvenirNextRoundedProDemi.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro DemiItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro DemiItalic'),
    url('../css/fonts/AvenirNextRoundedProDemiIt.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Bold'),
    url('../css/fonts/AvenirNextRoundedProBold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next Rounded Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Rounded Pro Bold Italic'),
    url('../css/fonts/AvenirNextRoundedProBoldIt.woff') format('woff');
}
/////////////////////////////////////////////////////////////////////////////////////////
/*  "CORE" STYLES */

:root {
  --kit-color-primary: #3a3a3e;
  --kit-font-family: 'Avenir Next Rounded Pro Medium', sans-serif;
}

html {
  font-size: 14px;
  @media (max-width: $sm-max-width) {
    font-size: 13px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}

strong {
  font-weight: $font-weight-bold;
  font-family: 'Avenir Next Rounded Pro Bold', sans-serif;
  line-height: 2em;
}

input {
  outline: none !important;
  font-family: $base-font-family;
  color: $text;
}

button,
input {
  box-shadow: none !important;
  outline: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

svg {
  vertical-align: initial;
  overflow: auto;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: $gray-2;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}
/* .cui__utils__heading{

} */
.ant-btn-round.ant-btn-lg {
  padding: 0 20px !important;
}

.bg-soft-secundary {
  background-color: $dark-gray-3;
  color: $white;
}

.react-flow__attribution.right {
  display: none !important;
}

.react-flow__handle.connectable {
  padding: 4px !important;
  border: 1px solid #65758b !important;
}

.react-flow__handle {
  background: #fff !important;
  padding: 4px !important;
}

.react-flow__handle-left {
  left: -7px !important;
}

.react-flow__handle-right {
  right: -7px !important;
}

// dark theme
[data-kit-theme='dark'] {
  body {
    background: $dark-gray-5;
    color: $dark-gray-1;
  }

  .badge-example {
    background: $dark-gray-4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dark-gray-1;
  }

  a {
    color: $dark-gray-1;
    &:hover,
    &:active,
    &:focus {
      color: $red;
      text-decoration: none;
    }
  }
}
