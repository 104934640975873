//
// user chat.scss
//
@import 'components/mixins.scss';

.user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  height: calc(100vh - 85px) !important;

  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh !important;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.basechat {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.user-chat-nav {
  display: flex;
  justify-content: flex-end;

  .ant-menu {
    background: none !important;
  }

  .ant-menu-horizontal {
    line-height: none;
    border-bottom: none;
  }

  .ant-menu-item {
    padding: 0 1px !important;
  }

  .nav-btn {
    height: 40px;
    width: 25px;
    line-height: 40px;
    box-shadow: none;
    border: none;
    padding: 0;
    font-size: 20px;
    color: $gray-5;
  }

  .nav-btn:hover {
    color: $red;
    background-color: $white;
  }

  @media (max-width: 575.98px) {
    display: flex;
    justify-content: flex-end;
  }
}

.headerChat {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  height: 80px;

  ul > li {
    margin-left: 1px;
    margin-right: 3px !important;
  }
}

.inputForm {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  height: 70px;
}

.chat-conversation {
  line-height: rem(25);
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 2 2 auto;
  -ms-flex: 2 2 auto;
  flex: 2 2 auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 100%;
  /*  height: calc(100vh - 20px);
  border: 1px solid red;
  @media (max-height: 840px) {
    height: calc(100vh - 190px);
    border: 1px solid purple;
  } */

  li {
    clear: both;
  }

  .chat-avatar {
    margin: 0 16px 0 0 /*rtl:0 0 0 16px*/;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .left {
    padding-right: 25%;
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;

    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      font-family: 'Avenir Next Rounded Pro Regular', sans-serif;
      position: relative;

      a {
        color: $yellow;
      }

      a:hover {
        text-decoration: underline;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }

      .replyMsg {
        background-color: $chat-primary-bg-reply;
        border-left: 4px solid $orange;
        color: $gray-3;
        margin-bottom: 5px;
        font-size: 13px;
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-6;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-status {
      font-size: 12px;
      color: rgba($white, 0.5);
    }

    .chat-time {
      margin-top: 4px;
      text-align: right;
      font-size: 14px;
      color: rgba($white, 0.5);
    }

    .chat-read {
      position: relative;
      bottom: -4px;
      font-size: 15px;
      color: rgba($white, 0.9);
    }

    .chat-sent {
      font-size: 15px;
      position: relative;
      bottom: -4px;
      color: rgba($white, 0.5);
    }

    .chat-sent a {
      font-size: 18px;
      color: $white !important;
      display: inline-block;
      text-decoration: underline;
      line-height: 30px;
      width: 30px;
      height: 30px;
      text-align: center;
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        max-width: 150px;
      }

      .message-img-link {
        position: absolute;
        right: 10px /*rtl:auto*/;
        left: auto /*rtl:0*/;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white !important;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    padding-left: 25%;

    .chat-avatar {
      order: 3;
      margin-right: 0;
      margin-left: 16px;
    }

    .chat-status {
      font-size: 12px;
      color: rgba($gray-6, 0.5);
    }

    .chat-time {
      text-align: left;
      color: rgba($gray-6, 0.5);
    }

    .chat-read {
      color: $green;
      margin-top: 5px;
    }

    .chat-sent {
      color: $gray-6;
      color: rgba($gray-6, 0.5);
    }

    .conversation-list {
      float: right;
      text-align: right;

      .ctext-wrap {
        justify-content: flex-end;

        .ctext-wrap-content {
          order: 2;
          background-color: $chat-secondary-bg;
          color: $dark;
          text-align: left;
          border-radius: 8px 8px 0 8px;

          a {
            color: $red;
          }

          a:hover {
            text-decoration: underline;
          }

          &:before {
            border: 5px solid transparent;
            border-top-color: $chat-secondary-bg;
            border-right-color: $chat-secondary-bg;
            left: auto;
            right: 0;
          }
        }
      }

      .dropdown {
        order: 1;
      }
    }

    .dot {
      background-color: $dark;
    }
  }
}

.list-inline-item li {
  border-color: $white !important;
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    border-color: $white !important;
    margin: 0;
  }
}

.chat-user-message {
  color: $primary;
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 2px;
    background: $red;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}
