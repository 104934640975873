//
// chat-leftsidebar.scss
//
@import 'components/mixins.scss';

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 400px;
    max-width: 420px;
    height: -moz-calc(80% - (73px));
    height: -webkit-calc(80% - (73px));
    height: calc(100% - (73px));
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    padding: 0 5px 0 5px;
    margin: 0;
    width: 45px;
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  [data-kit-theme='default'] .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  [data-kit-theme='default']
    .ant-tabs-right
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: $primary;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0;
    font-size: 22px;
  }

  /*   .ant-tabs .ant-tabs-left-content {
    padding-left: 10 !important;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    transform: rotate(-90deg) !important;
    margin: 0;
    padding: 35px 0;
  } */

  .user-status-box {
    background-color: $white;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    margin-top: 0;
    display: block;

    .chat-user-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.chat-search-box {
  margin-bottom: 20px;

  .search-icon-btn {
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 0;
  }
}

.chat-message-list {
  height: calc(100vh - 286px);

  @media (min-width: 1050px) {
    height: calc(100vh - 220px);
  }
}

.chat-group-list {
  height: calc(100vh - 320px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 194px);
  }
}

.chat-list {
  margin: 0;

  li {
    &.active {
      a {
        background-color: $light-pink;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 15px 10px;
      color: $gray-6;
      transition: all 0.4s;
      border-top: 1px solid $sidebar-sub-bg;
      border-radius: 5px;

      &:hover {
        background-color: $light-pink;
      }
    }

    .chat-user-message {
      font-size: 14px;
    }

    &.typing {
      .chat-user-message {
        color: $primary;
        font-weight: $font-weight-medium;

        .dot {
          background-color: $primary;
        }
      }
    }

    .unread-message {
      position: absolute;
      display: inline-block;
      right: 15px /*rtl:auto*/;
      left: auto /*rtl:0*/;
      top: 33px;
    }
  }
}

.chat-user-img {
  position: relative;

  .user-status {
    width: 14px;
    height: 14px;
    background-color: $white;
    border-radius: 50%;
    border: 2px solid $card-bg;
    position: absolute;
    right: auto /*rtl:auto*/;
    left: 0 /*rtl:0*/;
    bottom: 0;
  }

  .user-status img {
    right: 1px;
    top: -7px;
    position: relative;
  }

  &.online {
    .user-status {
      background-color: $success;
    }
  }

  &.away {
    .user-status {
      background-color: $warning;
    }
  }
}

// contact list

.contact-list {
  li {
    cursor: pointer;
    padding: 0;
  }
}

// setting

.profile-user {
  position: relative;
  display: inline-block;

  .profile-photo-edit {
    position: absolute;
    right: 0 /*rtl:auto*/;
    left: auto /*rtl:0*/;
    bottom: 0;
  }
}
